import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import {
  CombinedItemImage,
  ItemContainer,
} from "@/game-tft/CommonComponents.jsx";
import {
  BASIC_ITEMS,
  getCurrentSet,
  isFutureSet,
} from "@/game-tft/constants.mjs";
import getCombinedItem from "@/game-tft/get-combined-item.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import ItemTooltip from "@/game-tft/ItemToolTip.jsx";
import {
  ComponentItems,
  cssBigItem,
  cssItemNotSelected,
  cssItemSelected,
  cssMarginTop,
  cssRoundedItem,
  Grid,
  Items,
  Left,
  Row,
  Rows,
} from "@/game-tft/OverviewItems.style.jsx";
import ParsedDescription from "@/game-tft/ParsedDescription.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import SearchIcon from "@/inline-assets/search.svg";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import isEmpty from "@/util/is-empty.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const allowedItems = new Set(["advanced", "advancedArmory"]);
const filterItems = ["search", "item", "basic"];

function OverviewItems() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const { currentPath, searchParams } = useRoute();
  const locale = getLocale();
  const set = useSetByParam();
  const params = useMemo(
    () => filterItems.map((i) => searchParams.get(i)),
    [searchParams],
  );
  const isResetDisabled = useMemo(() => !(params[0] || params[1]), [params]);
  const filterItemByName = useCallback(
    (name) => {
      const str = params[0];
      if (typeof str === "string" && str.length) {
        return name.toLowerCase().includes(str.toLowerCase());
      }
      // By default, we don't filter unless the search param is present
      return true;
    },
    [params],
  );
  const itemFilters = getFilters(searchParams, "items", isFutureSet(set));
  const items = useMemo(() => {
    const itemStaticData = state.tft[set]?.items ?? {};
    const data = state.tft.stats.items?.[itemFilters]?.[set];
    const result = Object.fromEntries(
      Object.entries(itemStaticData).map((item) => {
        return [
          item[0],
          { ...item[1], tier: data?.[item[1].apiKey]?.tier ?? item[1].tier },
        ];
      }),
    );
    return result;
  }, [itemFilters, set, state.tft]);
  const itemsLocalized = useMemo(
    () => state.tft[set]?.localizedItems?.[locale] ?? {},
    [locale, set, state.tft],
  );
  const itemSelected = useMemo(() => {
    for (const key in items) {
      const item = items[key];
      if (item.apiKey === params[1]) {
        const title = itemsLocalized[item.apiKey]?.name ?? item.name;
        const description =
          itemsLocalized[item.apiKey]?.description ?? item.description;
        const [combinedItemUrl, basicItemUrl, componentItemUrl] = [item.apiKey]
          .concat(item.buildsFrom)
          .map((i) => StaticTFT.getItemImage(i, set));
        return {
          title,
          description,
          tier: item.tier,
          basicItemUrl,
          componentItemUrl,
          combinedItemUrl,
        };
      }
    }
    return null;
  }, [items, itemsLocalized, params, set]);
  const itemsFiltered = useMemo(() => {
    const results = [];
    for (const key in items) {
      const item = items[key];
      if (allowedItems.has(item.kind) && filterItemByName(item.name)) {
        results.push(item);
      }
    }
    return results;
  }, [filterItemByName, items]);
  const itemBasic = useMemo(
    () => BASIC_ITEMS.find((i) => i === params[2]) ?? BASIC_ITEMS[0],
    [params],
  );
  // Methods
  const handleSetSearch = useCallback(
    (value) => {
      const updatedSearchParams = new URLSearchParams(searchParams);
      if (value.length === 0) {
        updatedSearchParams.delete("search");
      } else {
        updatedSearchParams.set("search", value);
      }
      return updateRoute(currentPath, updatedSearchParams);
    },
    [currentPath, searchParams],
  );
  const handleSetItem = useCallback(
    (key) => {
      return () => {
        const updatedSearchParams = new URLSearchParams(searchParams);
        if (key === params[1]) {
          updatedSearchParams.delete("item");
        } else {
          updatedSearchParams.set("item", key);
        }
        return updateRoute(currentPath, updatedSearchParams);
      };
    },
    [currentPath, params, searchParams],
  );
  function handleSetBasic(key) {
    return () => {
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.set("basic", key);
      return updateRoute(currentPath, updatedSearchParams);
    };
  }
  // Render
  return (
    <Grid>
      <Card>
        <Left>
          <TextInput
            placeholder={t("tft:searchItems", "Search Items")}
            onValueChange={handleSetSearch}
            Icon={SearchIcon}
          />
          <div>
            <div className="type-body2">
              {t("tft:combinedItems", "Combined Items")}
            </div>
            <Items>
              {itemsFiltered.map((item, idx) => {
                const TierIcon = getTierIcon(item.tier);
                return (
                  <ItemContainer
                    key={idx}
                    onClick={handleSetItem(item.apiKey)}
                    className={
                      params[1] !== item.key && params[1]
                        ? cssItemNotSelected()
                        : null
                    }
                  >
                    <ItemTooltip item={item.apiKey}>
                      <CombinedItemImage
                        src={StaticTFT.getItemImage(item.apiKey, set)}
                        alt={item.apiKey}
                      />
                      {TierIcon ? (
                        <img
                          className="tier-icon"
                          src={TierIcon}
                          width="28"
                          height="28"
                        />
                      ) : null}
                    </ItemTooltip>
                  </ItemContainer>
                );
              })}
            </Items>
          </div>
          <ButtonGroup block>
            <Button href={currentPath} disabled={isResetDisabled}>
              {t("common:reset", "Reset")}
            </Button>
          </ButtonGroup>
        </Left>
      </Card>
      <Card>
        <div>
          {itemSelected ? (
            <Rows>
              <Row>
                <Item {...itemSelected} />
              </Row>
            </Rows>
          ) : (
            <>
              <div className="type-body2">
                {t("tft:basicItems", "Basic Items")}
              </div>
              <ButtonGroup block className={cssMarginTop()}>
                {BASIC_ITEMS.map((key, idx) => {
                  const isSelected =
                    params[2] === key || (idx === 0 && !params[2]);
                  return (
                    <Button
                      key={idx}
                      className={
                        isSelected ? cssItemSelected() : cssItemNotSelected()
                      }
                      onClick={handleSetBasic(key)}
                    >
                      <CombinedItemImage
                        className={cssRoundedItem()}
                        src={StaticTFT.getItemImage(key, set)}
                        alt={key}
                      />
                    </Button>
                  );
                })}
              </ButtonGroup>
              <Rows>
                {BASIC_ITEMS.map((key, idx) => {
                  const combinedItem = getCombinedItem(itemBasic, key, items);
                  if (isEmpty(combinedItem)) return null;
                  const [basicItemUrl, componentItemUrl, combinedItemUrl] = [
                    itemBasic,
                    key,
                    combinedItem.apiKey,
                  ].map((i) => StaticTFT.getItemImage(i, set));
                  const title =
                    itemsLocalized[combinedItem.apiKey]?.name ??
                    combinedItem?.name;
                  const description =
                    itemsLocalized[combinedItem.apiKey]?.description ??
                    combinedItem?.description;
                  return (
                    <Row key={idx}>
                      <Item
                        title={title}
                        description={description}
                        tier={combinedItem.tier}
                        basicItemUrl={basicItemUrl}
                        componentItemUrl={componentItemUrl}
                        combinedItemUrl={combinedItemUrl}
                      />
                    </Row>
                  );
                })}
              </Rows>
            </>
          )}
        </div>
      </Card>
    </Grid>
  );
}

export default {
  View: OverviewItems,
  FilterBar() {
    return null;
  },
};

export function meta() {
  const set = getCurrentSet()?.replace("set", "").replace("_", ".");
  return {
    title: ["tft:meta.items.overview.title", "TFT Items Overview"],
    description: [
      "tft:meta.items.overview.description",
      "Updated Teamfight Tactics TFT Items Cheatsheet, Builder, and Tierlist!",
    ],
    subtitle: [
      "tft:meta.items.overview.subtitle",
      "See all Set {{set}} TFT items, including their effects and recipes.",
      { set },
    ],
  };
}

function Item({
  basicItemUrl,
  componentItemUrl,
  combinedItemUrl,
  title,
  description,
  tier,
}) {
  const TierIcon = getTierIcon(tier);
  return (
    <>
      {basicItemUrl && componentItemUrl ? (
        <ComponentItems>
          <CombinedItemImage className={cssRoundedItem()} src={basicItemUrl} />
          +
          <CombinedItemImage
            className={cssRoundedItem()}
            src={componentItemUrl}
          />
        </ComponentItems>
      ) : null}
      <ItemContainer>
        <CombinedItemImage
          className={cssBigItem()}
          src={combinedItemUrl ?? basicItemUrl}
        />
        {TierIcon ? (
          <img className="tier-icon" src={TierIcon} width="28" height="28" />
        ) : null}
      </ItemContainer>
      <div>
        <div className="type-body2">{title}</div>
        <ParsedDescription
          className="type-caption color-shade1"
          text={description}
          iconSize={"var(--sp-4)"}
        />
      </div>
    </>
  );
}
