import { css, styled } from "goober";

export const Grid = styled("div")`
  display: grid;
  grid-template-columns: 230px 1fr;
  gap: var(--sp-4);
`;

export const Left = styled("div")`
  display: flex;
  flex-direction: column;
  gap: var(--sp-4);
`;

export const Items = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--sp-4);
  margin-top: var(--sp-2);
`;

export const ComponentItems = styled("div")`
  display: flex;
  gap: var(--sp-3);
`;

export const Rows = styled("div")`
  padding: var(--sp-8);
  display: flex;
  flex-direction: column;
  gap: var(--sp-8);
`;

export const Row = styled("div")`
  display: flex;
  gap: var(--sp-9);
  align-items: center;
  justify-content: flex-start;
`;

export const cssItemSelected = () => css`
  background: var(--shade6) !important;
  border: 1px solid var(--shade3) !important;
`;

export const cssItemNotSelected = () => css`
  opacity: 0.5 !important;
`;

export const cssRoundedItem = () => css`
  flex-shrink: 0;
  width: var(--sp-7) !important;
  height: var(--sp-7) !important;
  border-radius: 50% !important;
`;

export const cssMarginTop = () => css`
  margin-top: var(--sp-2);
`;

export const cssBigItem = () => css`
  width: var(--sp-11) !important;
  height: var(--sp-11) !important;
`;
