import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { getChampionsByTrait } from "@/game-tft/get-champions-by-trait.mjs";
import {
  Champions,
  Column,
  Columns,
  cssMarginRight,
  cssNotARealButton,
  TypeBonus,
  TypeBonusEffect,
  TypeBonuses,
  TypeBonusNeeded,
  TypeBox,
  TypeDescription,
  TypeRight,
} from "@/game-tft/OverviewTraits.style.jsx";
import ParsedDescription from "@/game-tft/ParsedDescription.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import TypeIcon from "@/game-tft/TypeIcon.jsx";
import UnitToolTip from "@/game-tft/UnitToolTip.jsx";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import { translateTraits } from "@/game-tft/use-traits.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function useTab() {
  const { searchParams } = useRoute();
  return useMemo(() => {
    const param = searchParams.get("tab");
    if (["classes", "origins"].some((i) => i === param)) return param;
    return "classes";
  }, [searchParams]);
}

function Item({ viewMode, trait, set, champions = [], isSkinsEnabled }) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const staticChampions = state.tft[set]?.champions;
  const traitKey = StaticTFT.getTraitKey(trait.apiKey, set);
  return (
    <TypeBox>
      <TypeIcon size={32} name={traitKey} className={cssMarginRight()} />
      <TypeRight>
        <TypeBonuses>
          <div className="type-body2">{translateTraits(t, traitKey, set)}</div>
          {trait.description ? (
            <TypeDescription>
              <ParsedDescription
                text={trait.description}
                iconSize={"var(--sp-4)"}
              />
            </TypeDescription>
          ) : null}
          {trait.bonuses.length > 0 &&
            trait.bonuses.map((bonus, idx) => (
              <TypeBonus
                key={idx}
                style={!idx ? { marginTop: "var(--sp-2" } : null}
              >
                <TypeBonusNeeded>
                  <span>{bonus.needed}</span>
                </TypeBonusNeeded>
                <TypeBonusEffect>
                  <ParsedDescription
                    text={bonus.effect}
                    iconSize={"var(--sp-4)"}
                  />
                </TypeBonusEffect>
              </TypeBonus>
            ))}
        </TypeBonuses>
        <Champions>
          {champions.map((champion, idx) => (
            <a
              key={idx}
              href={`/tft/champion/${champion.key}?set=${set.replace(
                "set",
                "",
              )}`}
              // Todo: There is a list of HTML data tips that are not imported yet
              // data-tip={getHtml({
              //   type: "champion",
              //   champion,
              // })}
            >
              <UnitToolTip
                set={set}
                champInfo={staticChampions[champion.key]}
                skinSetting={isSkinsEnabled}
              >
                <ChampionImage
                  champKey={champion.key}
                  cost={champion.cost}
                  size={["tablet", "mobile"].includes(viewMode) ? 32 : 40}
                  set={set}
                  skinSetting={isSkinsEnabled}
                />
              </UnitToolTip>
            </a>
          ))}
        </Champions>
      </TypeRight>
    </TypeBox>
  );
}

function OverviewTraits() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const set = useSetByParam(true);
  const [classes, origins] = useMemo(() => {
    const traits = state.tft[set]?.traits;
    const [classes, origins] = StaticTFT.getClassAndOriginsFromTraits(traits);
    return [classes, origins].map(Object.values);
  }, [set, state.tft]);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const viewMode = "desktop";
  if (viewMode === "mobile") {
    // Todo: mobile view overview traits, requires working viewMode hook, global, func or similar feature
    return null;
  }
  return (
    <Columns>
      {[
        [["tft:traits:origins", "Origins"], classes],
        [["tft:traits:classes", "Classes"], origins],
      ].map(([translation, data], idx) => (
        <Column key={idx}>
          <ButtonGroup block>
            <Button className={cssNotARealButton()}>{t(...translation)}</Button>
          </ButtonGroup>
          {data.map((i, idx) => {
            const champions = getChampionsByTrait(i.key, "all", set);
            return (
              <Item
                key={idx}
                trait={i}
                set={set}
                champions={champions}
                viewMode={viewMode}
                isSkinsEnabled={isSkinsEnabled}
              />
            );
          })}
        </Column>
      ))}
    </Columns>
  );
}

function OverviewTraitsFilterBar() {
  const viewMode = "desktop";
  const { t } = useTranslation();
  const { currentPath } = useRoute();
  const tab = useTab();
  function handleSetParam(value) {
    return () =>
      updateRoute(currentPath, new URLSearchParams({ tab: value }).toString());
  }
  if (viewMode === "mobile") {
    return (
      <ButtonGroup block>
        <Button active={tab === "classes"} onClick={handleSetParam("classes")}>
          {t("tft:traits:origins", "Origins")}
        </Button>
        <Button active={tab === "origins"} onClick={handleSetParam("origins")}>
          {t("tft:traits:classes", "Classes")}
        </Button>
      </ButtonGroup>
    );
  }
  return null;
}

export default {
  View: OverviewTraits,
  FilterBar: OverviewTraitsFilterBar,
};

export function meta() {
  return {
    title: ["tft:meta.traits.overview.title", "TFT Traits"],
    description: [
      "tft:meta.traits.overview.description",
      "Teamfight Tactics TFT Traits Overview, Statistics, Classes & origins, Traits, Synergies, Best Comps, Tierlist.",
    ],
  };
}
